onload(() => {
  switch (appData().native) {
    case 'iOS':
      window.webkit?.messageHandlers?.setDarkTheme?.postMessage(appData().darkTheme);
      window.webkit?.messageHandlers?.setToken?.postMessage(appData().token || '');
      break;
    case 'Android':
      window.android?.setToken(appData().token || '');
      break;
  }
    
}, {});