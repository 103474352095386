import 'turbolinks';

// if (Turbolinks) {
  onload(() => {
    document.addEventListener("turbolinks:request-start", function(event) {
      // console.log("HH")
      var xhr = event.data.xhr;
      xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop('content'));
    });
  
    document.addEventListener("turbolinks:before-cache", function() {
      // console.log("HH22")
      $('script[nonce]').each(function(index, element) {
        $(element).attr('nonce', element.nonce)
      })
    })
  })
// }