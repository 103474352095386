import toastr from 'toastr';

onload(() => {
  [
    "error",
    "info",
    "success"
  ].forEach(e => {
    if (appData()[e] && toastr[e]) {
      toastr[e](appData()[e]);
      delete appData()[e];
    }
  });
}, {});