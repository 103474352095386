function setStyles(styles, that = undefined) {
  if (!that) that = this;
  for (const k of Object.keys(styles)) that.style[k] = styles[k];
}

// HTMLElement Extension
HTMLElement.prototype.setStyles = setStyles;

try {
  Object.defineProperty(HTMLElement.prototype, 'styles', {
    get() {
      return this.style;
    },
    set(styles) {
      setStyles(styles, this);
    }
  });
} catch { }