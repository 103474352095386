require("@rails/ujs").start();
require("@rails/activestorage").start();
require("turbolinks").start();
// require("@/channels");
require("@/lib/core");
require("@/lib/initializers");
require("@/lib/ext");
require("@/lib/configs");
require("@/lib/socket");

const images = require.context('../images/', true);
const imagePath = (name) => images(name, true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true);
// const images = require.context('../images', true, /\.(gif|jpg|png|svg)$/i);
