// HTMLElement Extension
try {
  Object.defineProperty(HTMLElement.prototype, 'isLoading', {
    get() {
      return this.classList.contains('loading');
    },
    set(loading) {
      if (loading) this.classList.add('loading');
      else this.classList.remove('loading');
    }
  });
} catch { }