import pluralize from 'pluralize';

pluralize.addSingularRule(/data$/i, 'data');
pluralize.addSingularRule(/canvas$/i, 'canvas');
// pluralize.addPluralRule(/music$/i, 'musics'); // <- 이거 왜 안되는 지 모르겠음.
pluralize.addIrregularRule('music', 'musics');

String.prototype.toCamelCase = function() {
  return this
    .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
    .replace(/\s/g, '')
    .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
};

String.prototype.toSnakeCase = function() {
  return this
    .replace(/\.?([A-Z])/g,
      function (x, y) {
        return "_" + y.toLowerCase();
      }
    ).replace(/^_/, "");
};

String.prototype.pluralize = function() {
  return pluralize(this);
}

String.prototype.singular = function() {
  return pluralize.singular(this);
}

// String.prototype.toSnakeCase = function() {
//   return this
//     .replace(/\.?([A-Z])/g,
//       function (x, y) {
//         return "_" + y.toLowerCase();
//       }
//     ).replace(/^_/, "");
// };
