// vh설정
function setVh() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // console.log(vh);
}

onload(() => {
  setVh();
  window.addEventListener('resize', () => {
    setVh();
  });
}, {});